import { createRoot } from 'react-dom/client'
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'
import wrapRootElement from './src/wrap-root-element'

export { wrapRootElement }

export const replaceHydrateFunction = () => {
  return (element, container) => {
    createRoot(container).render(element)
  }
}
